import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  BiMicrophone,
  BiMicrophoneOff,
  BiVideo,
  BiVideoOff,
} from "react-icons/bi";
import { BsChatLeftDots, BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { MdOutlineCallEnd } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../styles/components/_joining-room-modal.scss";
import "../../styles/components/_meeting-preview.scss";
import Draggable from 'react-draggable';
import fetchData, { patchData } from "../../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import RemoteParticipant from "./RemoteParticipant/RemoteParticipant";
import ParticipantList from "./RemoteParticipant/ParticipantsList";
import { useDispatch, useSelector } from "react-redux";
import { videoMeetingSelectors } from "../../store/video-meeting/assessment.selectors";
import { setModal } from "../../store/editor/editor.actions";
import { editorSelectors } from "../../store/editor/editor.selectors";
import { SetStream, setAllParticipants } from "../../store/video-meeting/assessment.actions";
import { conversationSelectors } from "../../store/conversation/conversation.selectors";
import MessageDrawer from "./chat";
import { setChatOpen } from "../../store/conversation/conversation.actions";
import * as types from "../../types/editor";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const JoinRoom = ({roomId,roomObj,isAudioEnable,isVideoEnable}:RoomId) => {
  const {checkUserPermission} = useHasPermission()
  const id= roomId
  const [userName, setUserName] = useState<any>(null);
  const room= roomObj
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(true);
  const [minimizeScreen, setMinimizeScreen] = useState(false);
  const [participantLength, setParticipantLength] = useState(1);
  const [participants, setParticipants] = useState<any[]>([]);
  const [isLocalCameraEnable, setIsLocalCameraEnable] = useState(isVideoEnable);
  const [isLocalAudioEnable, setIsLocalAudioEnable] = useState(isAudioEnable);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [roomStatus,setRoomStatus]=useState("")
  const [loader,setLoader]=useState(false)
  const videoRef = useRef(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const {API_RECRUITMENT,API_ASSESSMENT} = getEnv()
  const  isEndInterView  = useSelector(videoMeetingSelectors.getInterviewState);
  const  stream  = useSelector(videoMeetingSelectors.getStream);
  const  {userRole}  = useSelector(editorSelectors.getAllState);
  const dispatch= useDispatch()

  const getUser = async () => {
    try {
      const result = await fetchData(`${API_RECRUITMENT}/api/user`);
      if (result) {
        {
          setUserName(`${result?.firstname} ${result?.lastname}`);
        }
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    checkUserPermission(['recruiter','candidate']) && getUser();
  }, []);

  useEffect(() => {
    if (room && checkUserPermission(['recruiter','candidate'])) {
      const participantArray = Array.from(room.participants.values(), (p) => ({
        participant: p,
      }));
      setParticipantLength(participantArray.length + 1);
      setParticipants(participantArray);
      const handleParticipantConnected = (participant) => {
        const remoteParticiapantLength = room?.participants.size;
        setParticipantLength((prev) => prev + 1);
        setParticipants((prevParticipants) => {
          return [...prevParticipants, { participant: participant }];
        });
      };
      const handleParticipantDisconnected = async (participant) => {
        const remoteParticiapantLength = room?.participants.size;
        setParticipantLength((prev) => prev - 1);
        setParticipants((prevParticipants) => {
          return prevParticipants.filter((p) => p.participant !== participant);
        });
         const resData = await fetchData(`${API_ASSESSMENT}/api/calendar/room-event/${roomId}`);
         if(resData && resData.status==="Completed"){
           dispatch(setModal(types.CodingModalsEnum.interviewEnded));
         }
      };
      room.on("participantConnected", handleParticipantConnected);
      room.on("participantDisconnected", handleParticipantDisconnected);
      return () => {
        room.off("participantConnected", handleParticipantConnected);
        room.off("participantDisconnected", handleParticipantDisconnected);
      };
    }
  }, [room]);



  useEffect(()=>{
    if(isVideoModalOpen){
      const videoTracks = videoRef.current
      ?.getInternalPlayer()
      ?.video?.srcObject?.getVideoTracks();
     const audioTracks = stream?.getAudioTracks();

    if (videoTracks) {
      videoTracks.forEach((track) => {
        track.enabled = !videoEnabled;
      });
    }
    if (audioTracks) {
      audioTracks.forEach((track) => {
        track.enabled = !audioEnabled;
      });
    }
  }
  })
  useEffect(()=>{
    if(userName===null){
      setLoader(true)
    }else{
      setLoader(false)
    }
  },[userName])

  const startVideoAndAudio = () => {
    const constraints = {
      video: videoEnabled,
      audio: audioEnabled,
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
     navigator.mediaDevices
        .getUserMedia(constraints)
        .then((userStream) => {
          dispatch(SetStream(userStream));
        })
        .catch((error) => {
          console.error("Error accessing camera and microphone:", error);
        });
    } else {
      console.error("getUserMedia is not supported on this browser.");
    }
  };

  useEffect(() => {
    startVideoAndAudio();
  }, []);

  useEffect(()=>{
    const endInterviewProcess= async()=>{
      disableMediaAccess()
      await fetchData(
        `${API_RECRUITMENT}/api/twilio/video-complete?roomName=${id}`
        ).then(()=>setRoomStatus("completed"));
        await patchData(`${API_ASSESSMENT}/api/calendar/event/${roomId}`,{"status":"Completed"});
        setMinimizeScreen(false)
        setIsVideoModalOpen(false);
      room.disconnect();
    }

    if(isEndInterView && checkUserPermission("recruiter")){
      endInterviewProcess();
    }
  },[isEndInterView])

  function disableMediaAccess() {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
      track.stop();
    })
    }
  }


  // useEffect(()=>{
  //   if(room?.state==="disconnected"){
  //     userRole === "candidate" ?
  //     dispatch(setModal(CodingModalsEnum.congratulations))
  //     : dispatch(setModal(CodingModalsEnum.completed));
  //     disableMediaAccess()
  //     setMinimizeScreen(false)
  //     setIsVideoModalOpen(false);
  //   }
  // },[room?.state])


  useEffect(()=>{
    const add=[room?.localParticipant?.identity]
    participants.map((participant, index) => {
    const identity = participant.participant.identity;
    add.push(identity);
  }
  )
  dispatch(setAllParticipants(add))
},[room,participants])
  return (
    <>
        {isVideoModalOpen && (
          <Modal
            setIsVideoModalOpen={setIsVideoModalOpen}
            room={room}
            participants={participants}
            participantLength={participantLength}
            setMinimizeScreen={setMinimizeScreen}
            isLocalAudioEnable={isLocalAudioEnable}
            isLocalCameraEnable={isLocalCameraEnable}
            setIsLocalAudioEnable={setIsLocalAudioEnable}
            setIsLocalCameraEnable={setIsLocalCameraEnable}
          />

        )}
        {minimizeScreen && (
          <MinimizeModal
            setMinimizeScreen={setMinimizeScreen}
            participants={participants}
            room={room}
            isLocalCameraEnable={isLocalCameraEnable}
            setIsVideoModalOpen={setIsVideoModalOpen}
            setIsLocalCameraEnable={setIsLocalCameraEnable}
            isLocalAudioEnable={isLocalAudioEnable}
            minimizeScreen={minimizeScreen}
            setIsLocalAudioEnable={setIsLocalAudioEnable}
          />
        )}
      </>
  );
};

export default JoinRoom;

export const Modal = ({
  setIsVideoModalOpen,
  room,
  participants,
  participantLength,
  isLocalAudioEnable,
  isLocalCameraEnable,
  setIsLocalAudioEnable,
  setMinimizeScreen,
  setIsLocalCameraEnable,
}) => {
  let [ searchParam, setSearchParam]=useSearchParams()
  const type=searchParam.get("type")

  const { isOpen, channel } = useSelector(conversationSelectors.getAllState);
  const navigate = useNavigate();
  const [participantScreenWidth, setParticipantScreenWidth] = useState(`49%`);
  const [participantScreenHeight, setParticipantScreenHeight] = useState(`49%`);
  const [loading, setLoading] = useState(false);
  const dispatch=useDispatch()

  // useEffect(() => {
  //   if (!channel) return;
  //   const listener = async (msg: Message) => {
  //     const newMessage = {
  //       sid: msg.sid,
  //       text: msg.body,
  //       username: msg.author,
  //       dateCreated: msg.dateCreated,
  //       timeAgo: calculateTimeAgo(msg.dateCreated),
  //       media: msg.media,
  //       memberSid: msg.memberSid,
  //     };

  //     dispatch(addMessage(newMessage));
  //   };

  //   channel.on("messageAdded", listener);
  //   return () => {
  //     channel.removeListener("messageAdded", listener);
  //   };
  // }, [channel]);

  // const calculateTimeAgo = (date: Date) => {
  //   const dateObject = new Date(date);
  //   const hours = dateObject.getHours();
  //   const minutes = dateObject.getMinutes();
  //   const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  //   return formattedTime;
  // };

  useEffect(() => {
    if (participantLength >= 5) {
      setParticipantScreenWidth("32.5%");
    } else if (participantLength === 4 && participantScreenWidth !== "49%") {
      setParticipantScreenWidth("49%");
    }
  }, [participantLength]);

  useEffect(()=>{
    if(participantLength===1){
      setTimeout(() => {
        setLoading(false);
      }, 30000);
    }
  },[participantLength])

  return (
    <div>
      {room && (
        <>
          <div className="modal-content liveCoding-modal">
            <div className="interview-container liveCoding-container">
          <div className="interview-container__video liveCoding-container__video" style={{ width: !isOpen && "100%" }}>
            { participantLength < 3 && (
              <div
                className={`video-content ${!isLocalCameraEnable && "active"}`}>
                {participantLength === 1 ?(
                  <>
                  <RemoteParticipant
                  participant={room?.localParticipant}
                  participantIndex={0}
                  room={room}
                  isLocal={true}
                  isLocalCameraEnable={isLocalCameraEnable}
                  setIsLocalCameraEnable={setIsLocalCameraEnable}
                  isLocalAudioEnable={isLocalAudioEnable}
                  setIsLocalAudioEnable={setIsLocalAudioEnable}
                  />
                   </>
                  ):(
                  <RemoteParticipant
                  participant={participants[0]?.participant}
                  participantIndex={0}
                  room={room}
                  isLocalCameraEnable={isLocalCameraEnable}
                  setIsLocalCameraEnable={setIsLocalCameraEnable}
                  isLocalAudioEnable={isLocalAudioEnable}
                  setIsLocalAudioEnable={setIsLocalAudioEnable}
                />
                )
                }
                {(participantLength === 2) && (
                  <div className="video-calling_participant-1">
                    {" "}
                    <RemoteParticipant
                      participant={ room?.localParticipant}
                      participantIndex={0}
                      room={room}
                      isLocal={true}
                      isLocalCameraEnable={isLocalCameraEnable}
                      setIsLocalCameraEnable={setIsLocalCameraEnable}
                      isLocalAudioEnable={isLocalAudioEnable}
                      setIsLocalAudioEnable={setIsLocalAudioEnable}
                    />
                  </div>
                )}
              </div>
            )}
            {participantLength >= 3  && (
              <>
                <div className="video-calling_participant-container">
                  <div
                    className="video-calling_participant-screen"
                    style={{
                      width: participantScreenWidth,
                      height: participantScreenHeight,
                    }}>
                    <RemoteParticipant
                      participant={room?.localParticipant}
                      room={room}
                      participantIndex={0}
                      isLocal={true}
                      isLocalCameraEnable={isLocalCameraEnable}
                      setIsLocalCameraEnable={setIsLocalCameraEnable}
                      isLocalAudioEnable={isLocalAudioEnable}
                      setIsLocalAudioEnable={setIsLocalAudioEnable}
                    />
                  </div>
                  {participants.map((participant, i) => (
                    <div
                      className="video-calling_participant-screen"
                      key={i}
                      style={{
                        width: participantScreenWidth,
                        height: participantScreenHeight,
                      }}>
                      <RemoteParticipant
                        participant={participants[i]?.participant}
                        room={room}
                        participantIndex={i}
                        isLocalCameraEnable={isLocalCameraEnable}
                        setIsLocalCameraEnable={setIsLocalCameraEnable}
                        isLocalAudioEnable={isLocalAudioEnable}
                        setIsLocalAudioEnable={setIsLocalAudioEnable}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            <div
              className={`${
                participantLength >= 4 ? "video-action active" : "video-action"
              }`}>
              <div className="video-main-action">
                <div
                  onClick={() => {
                    if (isLocalAudioEnable)
                      room.localParticipant.audioTracks.forEach(
                        (publication) => {
                          publication.track.disable();
                        }
                      );
                    if (!isLocalAudioEnable)
                      room.localParticipant.audioTracks.forEach(
                        (publication) => {
                          publication.track.enable();
                        }
                      );
                    setIsLocalAudioEnable((prev) => !prev);
                  }}
                  className="icon-action">
                  {isLocalAudioEnable ? (
                    <BiMicrophone className="icon" />
                  ) : (
                    <BiMicrophoneOff className="icon" />
                  )}
                </div>
                <div
                  onClick={() => {
                    room.disconnect();
                    setIsVideoModalOpen(false);
                    dispatch(SetStream(null));
                    navigate("/")
                  }}
                  className="icon-action disconnect">
                  <MdOutlineCallEnd className="icon" />
                </div>
                <div
                  className="icon-action"
                  onClick={() => {
                    if (isLocalCameraEnable) {
                      room.localParticipant.videoTracks.forEach(
                        (publication) => {
                          publication.track.disable();
                        }
                      );
                    }
                    if (!isLocalCameraEnable) {
                      room.localParticipant.videoTracks.forEach(
                        (publication) => {
                          publication.track.enable();
                        }
                      );
                    }
                    setIsLocalCameraEnable((prev) => !prev);
                  }}>
                  {isLocalCameraEnable ? (
                    <BiVideo className="icon" />
                  ) : (
                    <BiVideoOff className="icon" />
                  )}
                </div>
              </div>
              <div className="video-feature-action">
              <div className="icon-action">
                  <BsFullscreenExit
                    className="icon"
                    onClick={() => {
                      setIsVideoModalOpen(false);
                      setMinimizeScreen(true);
                    }}
                  />
                </div>
                <div className="icon-action" >
                <ParticipantList  participants={participants} localParticipant={room?.localParticipant.identity} participantLength={participantLength}/>
                </div>
                <div className="icon-action">
                  <BsChatLeftDots className="icon" onClick={()=>{
                    isOpen ? dispatch(setChatOpen(false)): dispatch(setChatOpen(true));
                  }}/>
                </div>
              </div>
            </div>
          </div>
          </div>
          {isOpen && <div className={`interview-container__chat liveCoding-container__chat ${isOpen ? 'opened' : ''}`}>
            <MessageDrawer room={room} />
          </div>}
        </div>
        </>
      )}
    </div>
  );
};

export const MinimizeModal = ({
  setMinimizeScreen,
  participants,
  room,
  isLocalCameraEnable,
  setIsLocalCameraEnable,
  isLocalAudioEnable,
  setIsLocalAudioEnable,
  minimizeScreen,
  setIsVideoModalOpen,
}) => {
  return (
   <Draggable>
     <div className="minimize-screen">
        <div className="header">
        <BsFullscreen
          onClick={() => {
            setIsVideoModalOpen(true);
            setMinimizeScreen(false);
          }}
          className="icon"
        />
        <AiOutlineClose
          className="icon"
          onClick={() => setMinimizeScreen(false)}
        />
      </div>
      {room && (
        <div className="video-container">
          <div className="video">
            <RemoteParticipant
              participant={room?.localParticipant}
              room={room}
              participantIndex={0}
              minimizeScreen={minimizeScreen}
              setMinimizeScreen={setMinimizeScreen}
              isLocal={true}
              isLocalCameraEnable={isLocalCameraEnable}
              setIsLocalCameraEnable={setIsLocalCameraEnable}
              isLocalAudioEnable={isLocalAudioEnable}
              setIsLocalAudioEnable={setIsLocalAudioEnable}
            />
          </div>
          {participants.map((participant, i) => (
            <div className="video" key={i}>
              <RemoteParticipant
                participant={participants[i]?.participant}
                room={room}
                participantIndex={i}
                minimizeScreen={minimizeScreen}
                setMinimizeScreen={setMinimizeScreen}
                isLocalCameraEnable={isLocalCameraEnable}
                setIsLocalCameraEnable={setIsLocalCameraEnable}
                isLocalAudioEnable={isLocalAudioEnable}
                setIsLocalAudioEnable={setIsLocalAudioEnable}
              />
            </div>
          ))}
        </div>
      )}
    </div>
   </Draggable>
  );
};

export interface Data {
  jobId?: any;
  CandidateId?: any;
}
export interface RoomId {
  roomId?: any;
  roomObj?:any;
  isVideoEnable?:any;
  isAudioEnable?:any
}
