import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCghAZ2U8dp2ROFFflz1X1YLkk_R3Y0p1Q",
  authDomain: "testdb-f66d2.firebaseapp.com",
  databaseURL: "https://testdb-f66d2-default-rtdb.firebaseio.com",
  projectId: "testdb-f66d2",
  storageBucket: "testdb-f66d2.firebasestorage.app",
  messagingSenderId: "212611238016",
  appId: "1:212611238016:web:1a895f9fb38efaa459be65"
};

firebase.apps.length === 0
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
  export default firebase;